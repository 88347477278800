//import "./App.css";
import React, { useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";

import AllRoutes from "./allRoutes";
import { useTranslation } from "react-i18next";
import { Toaster } from "react-hot-toast";
//import { history } from './helpers/history';
import "moment/locale/ar";
import "moment/locale/he";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "./redux/actions/loginActions";
import RabiImageModal from "./components/UI/RabiImageModel";
import { IS_CLIENT_SERVER, IS_DEBUG } from "./config/appConfig";
import { GlobalDebug } from "./utils/removeConsoles";
import Notification from "./firebaseNotifications/Notification";

export const currentDomain = window.location.hostname;

export const allowedDomains = IS_CLIENT_SERVER
  ? ["localhost", "toshavhaham.co.il"]
  : ["localhost", "15.207.152.121", "knp-tech.in"];

function App() {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const userProfile = useSelector(({ user }) => user.profile);
  const authUser = useSelector(({ auth }) => auth.data);

  const dispatch = useDispatch();

  GlobalDebug(IS_DEBUG)
  useEffect(() => {
    // console.log('App userProfile', i18n.language, userProfile.language_code)
    if (userProfile && i18n.language !== userProfile.language_code) {
      console.log("App userProfile FI", userProfile.language_code);
      i18n.changeLanguage(userProfile.language_code);
    }

    return () => {};
  }, [userProfile]);

  useEffect(() => {
    if (userProfile == undefined || userProfile == "") {
      if (authUser) {
        dispatch(getProfile(authUser.token))
          .then(response => {})
          .catch(error => {});
      }
    }
    return () => {};
  }, []);

  const visibleRabi = useMemo(() => <RabiImageModal />, [authUser]);
  return (
    <div className="App">
      <Router>
        <AllRoutes />
        <Toaster />
        <Notification />
      </Router>

      {/* {allowedDomains.includes(currentDomain) && !IS_DEBUG && visibleRabi} */}
    </div>
  );
}

export default App;
