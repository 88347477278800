export default {
  hello: "Hello",
  welcome: "Welcome",

  email: "Email",
  password: "Password",
  signIn: "Sign In",
  register: "Register",
  merchant: "Merchant",
  user: "User",
  fname: "First Name",
  lname: "Last Name",
  businessName: "Business Name",
  regNo: "Registration Number",
  website: "Website",
  location_url: "Location Url",
  emailAddress: "Email Address",

  createPassword: "Create Password",
  confirmPassword: "Confirm Password",
  phoneNumber: "Phone Number",
  idNumber: "ID Number",
  idNumbermsg:
    "You will have to add government id number in order to verify the profile.\nYou will have to visit the local post bank office and provide your registration information including government id proof which you have entered in the app while registration.\nAfter getting the verified users information from the post bank office, we will verify your profile.",
  streetAddName: "Street Address Name",
  streetNo: "Street Number",
  houseNo: "House Number",
  city: "City",
  district: "District",
  from: "From",
  to: "To",
  addBusinessLogo: "Add Business Logo",
  businessLogo: "Business Logo",
  businessHours: "Business Hours",

  validEmail: "please enter valid email.",
  validPassword: "please enter valid password.",

  signInAs: "Sign In As",
  donHaveAccount: "Don't Have an Account?",
  alreadyHaveAccount: "Already Have an Account?",
  haventReceivedOTP: "Haven't received OTP yet?",
  registerAs: "Register As",
  verify: "Verify",
  resend: "Resend",
  next: "Next",
  back: "Back",

  personalInformation: "Personal Information",
  morePersonalInfo: "More Personal Information",
  moreInfoBusiness: "More Information About Your Business",
  businessInformation: "Business Information",
  contactDetails: "Contact Details",
  businessActivity: "Business Activity",
  businessSector: "Business Sector",
  establishmentYear: "Establishment Year",

  maritalStatus: "Marital Status",
  numbersofChildren: "Numbers of Childrens",
  occupation: "Occupation",
  educationLevel: "Education Level",

  VerifyPhoneNumber: "Verify Email Address",
  enterOtp: "Enter One-Time Password (OTP)",
  otpMsg:
    "We have sent you One-Time Password (OTP) to your Registered Email Address.",

  languageSelector: "Select Your Language",

  changePassword: "Change Password",
  currentPassword: "Current Password",
  newPassword: "New Password",
  chooseLanguage: "Choose Language",
  languageSettings: "Language Settings",
  save: "Save",
  cancel: "Cancel",
  removeAccount: "Remove account",
  ok: "Ok",

  coupons: "Coupons",
  publicFeeds: "Torah collections",
  feeds: "Feeds",
  blogs: "Blogs",
  social: "Social",
  search: "Search",
  couponsMsg: "Showing all Coupons",
  blogsMsg: "Showing all Blogs",

  couponTitle: "Flat 20% Discount at McDonald's",
  couponDesc: "Lorem Ipsum Terms & Conditions will go here",
  couponCode: "MCD20",
  validaty: "Validity:",
  couponExpDate: " 31st May 2019",

  addBtn: "Add",
  updateBtn: "Update",
  editBtn: "Edit",
  viewBtn: "View",
  saveBtn: "Save",
  statsBtn: "Stats",

  // feedsSearch:"",
  feedTitle: "Dream Creations Event",
  feedDesc:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

  addComment: "Add Comment",

  blogTitle: "A Curious Fancy",
  blogDesc:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  category: "Category",
  sports: "Sports",
  report: "Report",
  reportMessage: "Are you sure you want to report this content?",
  readMore: "Read More",
  readLess: "Read Less",
  recentComments: "Recent Comments",

  coupon_title: "Coupon Title",
  coupon_title_ab: "Coupon Title (Arabic)",
  coupon_title_he: "Coupon Title (Hebrew)",
  coupon_description: "Coupon Description",
  coupon_code: "Coupon Code",
  discount_amount: "Discount Amount",
  discount_type: "Discount Type",
  qrcode_url: "QR code Url",
  storelocation: "Select Store Location",
  storecategory: "Select Category",
  create_date: "Create Date",
  expiry_date: "Expiry Date",
  term_condition: "Terms & Condition",

  dabitcardTitle:
    "You have already apply for the Israel 18 Debit Card. Please check below Status of your Israel 18 Debit Card Application.",
  dabitcardTitle1:
    "Being a valuable User of the Israel 18 App We are glad to provide you with the privilege of the Israel 18 Debit Card. Click on Apply Now to send your Application for the Israel 18 Debit Card; or click on Skip to continue to the Israel 18 App",
  addTerms: "Add Terms & Condition",

  apply_filters: "Apply Filters",
  apply: "Apply",
  clear_all: "Clear All",
  categories: "Categories",
  location: "Location",
  flat: "Flat",
  percentage: "Percentage",
  valid_all_stores: "Valid in All Stores",
  my_coupons: "My Coupons",

  thankyou: "Thank You",
  thankyou_msg: "We have received your Application to register as a Merchant with Israel 18 App. We are in the process to review your Application. you shall hear from us within 72 hours.",
  applyAgain: "Apply Again",
  cancelApplication: "Cancel Application",
  status: "Status",
  skip: "Skip",
  applyNow: "Apply Now",
  activeCoupons: "Active Coupons",
  inactiveCoupons: "Inactive Coupons",
  couponsAddedBy: "Coupons Added By",
  couponsUsedBy: "Coupons Used By",
  totalShare: "Total Share",
  whatsapp: "Whatsapp",
  shareOnWhatsApp: "Share on WhatsApp",
  shareOnEmail: "Share on Email",
  share: "Share",
  accept: "Accept",
  clearAll: "Clear All",
  code: "Code",
  OneTimePassword: "One Time Password (OTP)",
  selectItem: "Select item",
  review: "Review",
  reviewTitle: "How was your experience",
  submit: "Submit",
  chooseLibrary: "Choose From Library",
  image: "Image",
  video: "Video",
  loginwith: "Login with",
  or: "OR",
  document: "Document",
  gallery: "Gallery",
  audio: "Audio",
  Typemessage: "Type a message",

  media: "Media",
  shareMedia: "Share photos and videos",
  photos: "Photos",
  sharePhotos: "Share photos",
  videos: "Videos",
  shareVideos: "Share videos",
  shareAudios: "Share audio",
  file: "File",
  shareFile: "Share files",
  selectFile: "Select File",
  select: "Select",
  block: "Block",
  photo: "Photo",

  logout: "Logout",
  logout_message: "Do you want to logout?",
  delete_chat: "Remove Message",
  delete_chat_message: "Do you want to remove message?",
  delete_account_name: "Remove Account",
  delete_account_message: "Do you want to remove account?",
  delete_post: "Remove Post",
  delete_post_message: "Do you want to remove post?",
  block_user: "Block User",
  block_user_message: "Do you want to block user?",
  un_block_user: "Unblock User",
  un_block_user_message: "Do you want to Unblock user?",
  disconnect_user: "Disconnect User",
  disconnect_user_message: "Do you want to disconnect user?",

  message: "Message",
  edit_profile: "Edit Profile",
  posts: "Posts",
  connects: "Connects",
  connect: "Connect",
  connected: "Connected",
  account_privacy: "Account Privacy",
  public: "Public",
  private: "Private",
  private_account: "Private Account",
  blocked_users: "Blocked Users",
  privacy_msg1:
    "When your account is public, your profile and posts can be seen by anyone, on or off Israel 18, even if they don't have an Israel 18 account.",
  privacy_msg2:
    "When your account is private, only the Connections you approve can see what you share, including your photos or videos, and your Connections lists.",
  go_back: "Go back",
  create_post: "Create Post",
  post_upload_msg: "Click to upload your image and video",
  post_error_msg: "Please upload your post image or video",
  comments: "Comments",
  delete: "Delete",
  confirm: "Confirm",
  under_maintenance: "App is currently under maintenance.",
  postCreateMsg: "write a caption...",
  disconnect: "Disconnect",
  unblock: "Unblock",
  shabBatTital: "Active observers Shabbat",
  shabBatDesc:
    "I would like to be exposed to advertisers who are Shabbat-observant and/or those who define their account as Shabbat-observant",
  on: "ON",
  off: "OFF",
  bio: "Bio",
  user_name: "User Name",
  link: "Link",
  views: "Views",
  view: "View",
  shabBat: "Shabbat",
  accountIsPrivate: "This Account is Private",
  locationNotFound: 'Location not found',
  requested:'Requested',
  seemore:'See More',
  broadcastEnd:'Broadcasting was ended',
  cancelRequsted:'Cancel requested',
  cancelRequsted_message:"Do you want to cancel the requested?",
  gender:'Gender',
  yourlocation: "Select Your City",
  profileComplete:'Complete Profile',
  profileComplete_msg:'Please complete your profile.',
  isLive:"is live!",
  goToLive:"Go to Live",
  leaveRoom:"Leave Room",
  live:"Live",

  //web app text
  popularCategories: "Popular Categories",
  latestCoupon: "Latest Coupons",
  latestBlogs: "Latest Blogs",
  recentPost: "Recent Posts",
  copyCode: "Copy Code",
  couponMsg: "Use this coupon code at checkout",
  loadMore: "Load More",
  seeAllBlogs: "See All Blogs",
  upto: "UP TO",
  off: "OFF",
  downloadApp: "Download Our Application",
  allRightReserved: "Israel 18 All Right Reserved @2023",
  loginRequire: "You must be logged in",
  type:"Type",
  selectAudience:"Select Audience",
};
