export default {
    home: 'Дом',
    login: 'связь',
    register: "зарегистрироваться",
    settings: 'Определения',
    signin: 'Соединять',
    otpView: 'OtpView',
    myCoupons: 'мои купоны',
    addCoupon: 'Добавить купон',
    editCoupon: 'Изменить купон',
    myCouponsClient: 'мои купоны',
    backCouponsList: 'Вернуться к списку купонов',
    backPublicFeed: 'Вернемся к Аспи Тора.',
    couponStatistics: 'Статистика купонов',
    backBlogs: 'Вернуться к блогам',
    myProfile:"мой профайл",
    myProfileMerchant:"мой профайл",
    smartCitizenDebitCard: "Тошав Хахам",
    aboutSmartCitizenApp:"о нас",
    notification:"Оповещения",
    help:"помощь",
    termsncondition:"Условия и положения",
    logout:"Выйти",
    forgotPassword: 'Забыли пароль',
    resetPassword: 'сбросить пароль',
    generateOtp: 'Создать код',
    couponReview: 'обзоры',
    messages:"Сообщения",
    accountPrivacy:"Конфиденциальность аккаунта",
    settingsPrivacy:"Настройки и конфиденциальность",
    postDetail:"Подробности публикации",
    socialHome:"Публичная лента",
    postCreate:"Создать сообщение",
    postProfile:"Социальный профиль",
    chat:"Чат",
    blockedUsers:"Заблокированные пользователи",
    connectedUsers:"Подключенные пользователи",
    likes:"Нравится",


      //web app text
  guestuser: 'Гость пользователь',
  social: "Социальное",
  };