import Loadable from "react-loadable";

import { Loading } from "../../common/navigation";
import { BASPATH } from "../../config/appConfig";

const JoinViewer = Loadable({
  loader: () => import("./components/JoinViewer"),
  loading: Loading
});
const AdminBroadcast = Loadable({
  loader: () => import("./components/JoinBroadcast/adminLive"),
  loading: Loading
});
const UserBroadcast = Loadable({
  loader: () => import("./components/UserBroadcast"),
  loading: Loading
});

export const routesLiveStreamViewer = [
  {
    path: BASPATH + "live-join-viewer/:itemId",
    name: "live-join-viewer",
    element: JoinViewer
  },
  
];
export const routesLiveStreamBroadcaster = [
  {
    path: BASPATH + "live-join-broadcaster/:itemId",
    exact: true,
    name: "live-join-broadcaster",
    element: AdminBroadcast
  },
  {
    path: BASPATH + "user-broadcaster/:itemId",
    exact: true,
    name: "user-broadcaster",
    element: UserBroadcast
  }
];

export * from "../auth/components";
