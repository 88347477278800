export default {
  email: "אימייל",
  password: "סיסמא",
  //signIn: "כניסה",
  signIn: "כניסה",
  register: "הרשמה",
  merchant: "סוֹחֵר",
  user: "משתמש",
  fname: "שם פרטי",
  lname: "שם משפחה",
  businessName: "שם משתמש",
  regNo: "ח.פ/ת.ז",
  website: "כתובת אתר",
  location_url: "כתובת אתר של מיקום",
  emailAddress: "כתובת אימייל",

  createPassword: "צור סיסמא",
  confirmPassword: "אימות סיסמא",
  phoneNumber: "מספר טלפון",
  idNumber: "מספר ת.ז",
  idNumbermsg: "תצטרך להוסיף מספר תעודת זהות ממשלתית כדי לאמת את הפרופיל.\nתצטרך לבקר בבנק הדואר המקומי ולספק את פרטי הרישום שלך כולל הוכחת תעודה מזהה ממשלתית שהזנת באפליקציה בזמן ההרשמה.\nלאחר קבלת האימות מידע על המשתמשים ממשרד הדואר, אנו נאמת את הפרופיל שלך.",
  streetAddName: "שם רחוב",
  streetNo: "מספר רחוב",
  houseNo: "מספר הבית",
  city: "עיר",
  district: "אזור",
  from:"משעה:",
  to:"עד שעה:",
  addBusinessLogo:"הוסף לוגו",
  businessLogo: "לוגו עסקי",
  businessHours:"שעותפעילות",



  validEmail: "please enter valid email.",
  validPassword: "please enter valid password.",

  signInAs: "הירשם כ:",
  donHaveAccount: "לא נרשמתי ",
  alreadyHaveAccount: "כבר יש לך חשבון?",
  haventReceivedOTP: "לא קיבלתקוד?",
  registerAs: "הרשמה בתור",
  verify:"הזדהות",
  resend:"להתחלה",
  next: "הבא",
  back: "אחורה",

  personalInformation: "פרטים אישיים",
  morePersonalInfo: "ספרו עלעצמכם",
  moreInfoBusiness: "ספרו על העסק שלכם",
  businessInformation: "מידע עסקי",
  contactDetails: "צור קשר",
  businessActivity: "פעילותעיסקית",
  businessSector: "תחום  עיסקי",
  establishmentYear: "שנת הקמה",
  test: "",

  maritalStatus: "מצב נוכחי",
  numbersofChildren: "מספרילדים",
  occupation: "סטטוס",
  educationLevel: "השכלה",

  VerifyPhoneNumber: "אימות כתובת מייל",
  enterOtp: "הכנס קוד אימות",
  otpMsg: "שלחנו לך סיסמה חד פעמית, לכתובת הדואל הרשומה שלך.",


  languageSelector: 'בחר את השפה שלך',

  changePassword: "החלפת סיסמה",
  currentPassword: "סיסמה נוכחית",
  newPassword: "סיסמה חדשה",
  chooseLanguage: "בחר שפה",
  languageSettings: "שפות",
  save: "שמירה",
  cancel: "ביטול",
  removeAccount: "הסר חשבון",
  //ok:"בסדר",

  coupons: "קופונים",
  publicFeeds: "ליקוטי תורה",
  feeds: "פרופיל אישי",
  blogs: "בלוג",
  search: "חיפוש",
  //couponsMsg: "הצג את כל הקופונים בסביבה",
  couponsMsg: "מציג את כל הקופונים",
  blogsMsg: "בלוגים",
//"חיפוש פרופיל אישי",

  // couponFilterMsg:"",
  // couponSearch:"",

  couponTitle:"פחות20% הנחהמקדונלד",
  couponDesc:"תקנוןותנאים",
  couponCode:"MCD20",
  validaty:"תוֹקֶף:",
  couponExpDate:"31st May 2019",

  addBtn: "לְהוֹסִיף",
  updateBtn: "עדכון",
  editBtn: "עריכה",
  viewBtn: "תצוגה",
  saveBtn: "לשמור",
  statsBtn: "סטטיקות",

 // feedsSearch:"",
  feedTitle:"צור",
  feedDesc:"Lorem Ipsum הוא פשוט טקסט דמה של תעשיית הדפוס והקביעה. Lorem Ipsum היה טקסט הדמה הסטנדרטי של התעשייה מאז שנות ה-1500, כאשר מדפסת לא ידועה לקחה שולחן כתיבה וערבבה אותה כדי ליצור ספר דגימות.",

  addComment:"תגובה",

  blogTitle:"פנסי סקרן",
  blogDesc:"Lorem Ipsum הוא פשוט טקסט דמה של תעשיית הדפוס והקביעה. Lorem Ipsum היה טקסט הדמה הסטנדרטי של התעשייה מאז שנות ה-1500, כאשר מדפסת לא ידועה לקחה שולחן כתיבה וערבבה אותה כדי ליצור ספר דגימות.",
  category:"קטגוריה",
  sports:"ספורט",
  report:"דווח",
  reportMessage: "האם אתה בטוח שברצונך לדווח על תוכן זה?",
  readMore: "קרא עוד",
  readLess: "תקרא פחות",
  recentComments: "תגובות אחרונות",

  coupon_title:"כותרת הקופון",
  coupon_title_ab:"כותרת הקופון (Arabic)",
  coupon_title_he:"כותרת הקופון (Hebrew)",
  coupon_description:"תיאור קופון",
  coupon_code:"קוד קופון",
  discount_amount:"כמות ההנחה",
  discount_type:"סוג הנחה",
  qrcode_url:"כתובת URL של קוד QR",
  storelocation:"בחר מיקום חנות",
  storecategory:"בחר קטגוריה",
  create_date:"צור תאריך",
  expiry_date:"תאריך תפוגה",
  term_condition:"תנאים והגבלות",
  

  dabitcardTitle:"כבר הגשת בקשה כ-  \"תושב חכם ישראל\". אנא בדוק למטה את סטטוס הבקשה שלך.",
  dabitcardTitle1:"להיות משתמש בעל ערך באפליקציית \"תושב חכם ישראל\" אנו שמחים לספק לך את ההרשאה של כרטיס נטען תושב חכם. לחץ על הגש עכשיו כדי לשלוח את הבקשה שלך לכרטיס החיוב של תושב חכם, או לחץ על דלג כדי להמשיך לאפליקציית תושב חכם.",
  addTerms:"הוסף תנאים והגבלות",

  apply_filters:"הוסף מסננים",
  apply:"ליישם",
  clear_all:"נקה הכל",
  categories:"קטגוריות",
  location:"מקום",
  flat:"קבוע",
  percentage:"אֲחוּזִים",
  valid_all_stores:"תקף בכל החנויות",
  my_coupons:"הקופונים שלי",

  thankyou:"תודה",
  thankyou_msg:"קיבלנו את הבקשה שלך להירשם כבעל עסק באפליקציית \"תושב חכם ישראל\". אנו נמצאים בתהליך לבדיקת הבקשה שלך. אתה תשמע מאיתנו תוך 72 שעות.",
  applyAgain:"הגש בקשה שוב",
  cancelApplication:"בטל את הבקשה",
  status:"סטָטוּס",
  skip:"לדלג",
  applyNow:"הגש בקשה עכשיו",
  ok:"בסדר",
  activeCoupons:"קופונים פעילים",
  inactiveCoupons:"קופונים לא פעילים",
  
  couponsAddedBy:"קופונים הוספו על ידי",
  couponsUsedBy:"קופונים בשימוש על ידי",
  totalShare:"שיתוף כולל",
  whatsapp:"וואטסאפ",
  shareOnWhatsApp:"שתפו בווטסאפ",
  shareOnEmail:"שתף באימייל",
  share:"לַחֲלוֹק",
  accept:"לְקַבֵּל",
  code:"קוד",
  OneTimePassword:"סיסמה חד פעמית (OTP)",
  selectItem:"בחר פריט",
  review: "סקירה",
  reviewTitle: "איך הייתה החוויה שלך",
  submit:"שלח",
  chooseLibrary:"בחר מהספרייה",
  image:"תמונה",
  video:"וִידֵאוֹ",
  loginwith: "התחבר עם",
  or: "אוֹ",
  document: "מסמך",
  gallery: "גלריה",
  audio: "שֶׁמַע",
  Typemessage: "הקלד הודעה",

  media: "גלריית תמונות",
  shareMedia: "שתף תמונות וסרטונים",
  photos: "תמונות",
  sharePhotos: "שתף תמונות",
  videos: "סרטונים",
  shareVideos: "שתף סרטונים",
  shareAudios: "שתף אודיו",
  file: "קוֹבֶץ",
  shareFile: "לשתף מסמכים",
  selectFile: "בחר קובץ",
  select: "בחר",
  block: "לַחסוֹם",
  photo: "תמונה",
 

  logout:"להתנתק",
  logout_message:"האם אתה רוצה להתנתק",
  delete_chat:"הסר הודעה",
  delete_chat_message:"האם ברצונך להסיר הודעה?",
  delete_account_name:"הסר חשבון",
  delete_account_message:"האם אתה רוצה להסיר את החשבון?",
  delete_post:"הסר פוסט",
  delete_post_message:"האם אתה רוצה להסיר את הפוסט?",
  block_user:"חסום משתמש",
  block_user_message:"האם אתה רוצה לחסום משתמש?",

  message:"הוֹדָעָה",
  edit_profile:"ערוך פרופיל",
  posts:"פוסטים",
  connects:"חברים",
  connect:"עקוב",
  connected:"מְחוּבָּר",
  account_privacy:"פרטיות חשבון",
  public:"פּוּמְבֵּי",
  private:"פְּרָטִי",
  private_account:"חשבון פרטי",
  blocked_users:"משתמשים חסומים",
  privacy_msg1:"כאשר החשבון שלך ציבורי, כל אחד יכול לראות את הפרופיל והפוסטים שלך, בתושב חכם או מחוצה לו, גם אם אין לו חשבון תושב החכם.",
  privacy_msg2:"כאשר החשבון שלך פרטי, רק החיבורים שאתה מאשר יכולים לראות מה אתה משתף, כולל התמונות או הסרטונים שלך, ורשימות החיבורים שלך.",
  go_back:"לחזור",
  create_post:"צור פוסט",
  post_upload_msg:"לחץ כדי להעלות את התמונה והסרטון שלך",
  post_error_msg:"אנא העלה את תמונת הפוסט או הסרטון שלך",
  comments:"הערות",
  delete:"לִמְחוֹק",
  confirm:"לְאַשֵׁר",
  under_maintenance:"האפליקציה נמצאת כרגע בתחזוקה.",
  postCreateMsg:"הקלידו תיאור…",
  disconnect:"להתנתק",
  unblock:"בטל את החסימה",
  shabBatTital:"הגדרת הפרופיל  כשומר שבת",
  shabBatDesc:"ברצוני להיחשף למפרסמים שהם שומרי שבת ו/או כאלה שמגדירים את חשבונם כשומרי שבת ובעלי תשובה, ומקפידים על שמירת העיניים ו׳לשון הרע לא מדבר אלי׳.",
  on:"פעיל",
  off:"כבוי",
  bio:"על עצמי",
  user_name:"שם משתמש",
  link:"קישור",
  views:"צפיות",
  view:"נוף",
  shabBat:"שבת",
  accountIsPrivate:"חשבון זה הוא פרטי" ,
  locationNotFound: 'המיקום לא נמצא',
  requested:'התבקש',
  seemore:'ראה עוד',
  broadcastEnd:'השידור הסתיים',
  cancelRequsted:'התבקש לבטל',
  cancelRequsted_message:"האם ברצונך לבטל את המבוקש?",
  gender:'מִין',
  yourlocation:  "בחר את העיר שלך",
  profileComplete:'פרופיל מלא',
  profileComplete_msg:'אנא השלם את הפרופיל שלך.',
  isLive:"הוא חי!",
  goToLive:"בשידור חי עבור אל",
  leaveRoom:"עזוב את החדר",
  live:"בשידור חי",



  
 
  //web app text
  popularCategories: "קטגוריות פופולאריות",
  latestCoupon: "קופונים אחרונים",
  latestBlogs: "הבלוגים האחרונים",
  recentPost: "הודעות האחרונות",
  copyCode: "העתק קוד",
  couponMsg: "השתמש בקוד קופון זה בקופה",
  loadMore: "טען עוד",
  seeAllBlogs: "ראה את כל הבלוגים",
  upto: "עד ל",
  off: "כבוי",
  downloadApp: "הורידו עכשיו את האפליקציה",
  allRightReserved: "2023@ כל הזכויות שמורות ׳תושב חכם׳ | ד׳ בשבט תשפ״ג",
  loginRequire: "אתה חייב להיות מחובר",
  type:"סוּג",
 selectAudience:"בחר קהל" 
  
   

};