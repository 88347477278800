
import "./filters.css";
import React, { useEffect, useState } from 'react'
import Multiselect from 'multiselect-react-dropdown';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import { default as ReactSelect } from "react-select";
import { isRTL } from "../../../utils/common";
import { search_ } from "../../../constants/images";


const Filters = ({ isCouponScreen, couponCategory, couponDisplayCategory, onCouponCallBack, couponsCategoryList = [],
    blogsCategoryList = [],
    onBlogCallBack,
    onCloseFilters, showFilter, searchQuery, handleInputChange, searchPlaceHolder,
    showPublicFeeds=false }) => {

    const { t, i18n } = useTranslation();

    const categories = useSelector(({ coupon }) => coupon.couponsCategory);
    const locationList = useSelector(({ coupon }) => coupon.locationList);

    const [selectedTab, setSelectedTab] = useState(isCouponScreen ? 1 : 1);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedDiscount, setselectedDiscount] = useState('');
    const [categoryDataArray, setCategoryDataArray] = useState([]);
    const [locationData, setLocationData] = useState([])
    const [selectedLocation, setSelectedLocation] = useState([])

    //options: [{name: 'Option 1️', id: 1},{name: 'Option 2️', id: 2}]

    // console.log('couponsCategoryList>>>>', categoryDataArray)
    // console.log('selectedCategory>>>>', selectedCategory)
    useEffect(() => {
        const DataArray = isCouponScreen ? couponsCategoryList : blogsCategoryList;
        if (DataArray) {
            setCategoryDataArray(DataArray.map((item, index) => {
                const labelKey = i18n.language === 'he' ? item.category_name_he : i18n.language === 'ar' ? item.category_name_ab : item.category_name
                return { label: labelKey, value: String(item.category_id) }
            }))
        }
        setSelectedTab(isCouponScreen ? 1 : 1)
    }, [isCouponScreen, categories])

    useEffect(() => {
        if (locationList)
            setLocationData(locationList.map((item, index) => {
                const labelKey = i18n.language === 'he' ? item.city_area_he : i18n.language === 'ar' ? item.city_area_ab : item.city_area
                return { label: labelKey, value: String(item.id) }
            }))
        return () => {
        }
    }, [locationList])

    useEffect(() => {
        setSelectedCategory(couponDisplayCategory)
    }, [couponDisplayCategory])

    const clearAll = () => {
        console.log('clearAll')
        setSelectedCategory([])
        setselectedDiscount('')
        setSelectedLocation([])
    };

    const onSelectCategory = (selectedList, selectedItem) => {
        //   console.log('onSelectCategory >>>>>>>>>>>>', selectedList)
        setSelectedCategory(selectedList)
    }

    const onRemoveCategory = (selectedList, removedItem) => {
        setSelectedCategory(selectedList)
    }

    const onSelectLocation = (selectedList, selectedItem) => {
        setSelectedLocation(selectedList)
    }

    const onRemoveLocation = (selectedList, removedItem) => {
        setSelectedLocation(selectedList)
    }

    // console.log('selectedCategory', selectedCategory)
    const labelField = i18n.language === 'he' ? "label_he" : i18n.language === 'ar' ? 'label_ar' : "label"
    return (
        <>
            <div id="filters">
                <div className="p-3">
                    <p className="p">{t("common:search")}</p>
                    <div className="mt-1" />
                    <div className="filter-header">
                        <div className="search-wrapper" dir={isRTL(i18n.language) ? "rtl" : ""}>
                            <input
                                value={searchQuery}
                                onChange={handleInputChange}
                                placeholder={searchPlaceHolder}
                            />
                            <div className="search-icon-wrapper">
                                <img src={search_} alt="Logo" width="12px" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!showPublicFeeds && <div id="filters">

                <div className="filter-header">
                    <h4 className="h4">
                        {t("common:apply_filters")}
                    </h4>
                    <span onClick={clearAll} className="clear pointer">
                        {t('common:clear_all')}
                    </span>
                </div>
                <div className="divider"></div>

                <div className="p-2">
                    <span>
                        {t("common:categories")}
                    </span>
                    <ReactSelect
                        options={categoryDataArray}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isSearchable
                        placeholder={t("common:selectItem")}
                        labelField={labelField}
                        onChange={onSelectCategory}
                        allowSelectAll={false}
                        value={selectedCategory}
                    />
                </div>

                {
                    isCouponScreen && <div className="p-2">
                        <span>
                            {t("common:location")}
                        </span>

                        <ReactSelect
                            options={locationData}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            // components={{
                            //     Option
                            // }}
                            isSearchable
                            placeholder={t("common:selectItem")}
                            onChange={onSelectLocation}
                            allowSelectAll={false}
                            value={selectedLocation}
                        />
                    </div>
                }

                {isCouponScreen && <div className="p-2">
                    <span>
                        {t("common:discount_type")}
                    </span>

                    <div className="mb-3">
                        <div className="col-4 d-flex align-items-center">
                            <input
                                type="radio"
                                value={selectedDiscount}
                                checked={selectedDiscount === "Flat"}
                                onChange={() => { setselectedDiscount("Flat") }}
                            />
                            <span className='discounttype ms-1 me-1'>
                                {t('common:flat')}
                            </span>

                        </div>

                        <div className="col-4 d-flex align-items-center">
                            <input
                                type="radio"
                                value={selectedDiscount}
                                checked={selectedDiscount === "Percentage"}
                                onChange={() => { setselectedDiscount("Percentage") }}
                            />
                            <span className='discounttype ms-1 me-1'>
                                {t('common:percentage')}
                            </span>
                        </div>

                    </div>
                </div>}

                <div className="p-2">
                    <div className="apply-box pointer" onClick={() => {

                        let arrayLocation = []
                        selectedLocation.length > 0 && selectedLocation.forEach((item, index) => {
                            arrayLocation.push(item.value)
                        })

                        let arrayCategory = []
                        selectedCategory.length > 0 && selectedCategory.forEach((item, index) => {
                            arrayCategory.push(item.value)
                        })


                        const params = {
                            category_id: arrayCategory || [],
                            discount_type: selectedDiscount,
                            location_id: arrayLocation || [],
                        }

                        console.log('arrayCategory new ', arrayCategory)
                        console.log('arrayLocation new ', arrayLocation)
                        isCouponScreen ? onCouponCallBack(params) : onBlogCallBack(params)
                    }}>
                        <span className="text-apply">
                            {t("common:apply")}
                        </span>
                    </div>
                </div>
            </div>}
            
        </>

    )
}

export default Filters